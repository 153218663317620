import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ReactMarkdown from 'react-markdown'

export const FaqPageTemplate = (props) => {
  const data = props.questions;

  return <>
    <h1>{data.title}</h1>

    {data.questions.map((question, i) =>
      <div key={i}>
        <h4>{question.question}</h4>

        <ReactMarkdown>{question.answer}</ReactMarkdown>
      </div>
    )}
  </>
}

export const FaqPage = (props) => {
  const questions = props.data.markdownRemark.frontmatter;

  return <>
    <Layout>
      <div className="container">
        <FaqPageTemplate questions={questions} />
      </div>
    </Layout>
  </>
}

export default FaqPage;

export const faqQuery = graphql`
query FaqQuery {
  markdownRemark(frontmatter: { templateKey: { eq: "faq-page" } }) {
    frontmatter {
      title
      questions {
        question
        answer
      }
    }
  }
}
`;
